import { getSuitBySubdomain } from "modules/user";
import { v4 as uuidv4 } from "uuid";
import productsNames from "../../consts/productsNames";
import { productExists } from "../utils";

const generateProductRoutes = (products) => {
  if (!products) return [];

  const hasAppBackend = productExists(products)(productsNames.appBackend);
  const hasMicroPage = productExists(products)(
    productsNames.mobileLandingPages
  );
  const hasWebPush = productExists(products)(productsNames.webPushNotification);
  const hasWhatsApp = productExists(products)(productsNames.whatsApp);
  const hasSMS = true;
  const hasEmail = productExists(products)(productsNames.email);
  const hasAutomator = productExists(products)(productsNames.automator);

  let subdomain = window.location.pathname.split("/app/")[1];
  if (subdomain.includes("/")) subdomain = subdomain.split("/")[0];

  const currentSuit = getSuitBySubdomain(subdomain);

  const isMobileMarketting = currentSuit.type === "mobile_marketing";
  const isShopify = currentSuit?.source?.toLowerCase() === "shopify";
  const isZid = currentSuit?.source?.toLowerCase() === "zid";
  const isSalla = currentSuit?.source?.toLowerCase() === "salla";

  const campaignChildren = [];

  if (hasWebPush) {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "Push Campaigns",
      url: "/app/listWebPushCampaigns",
    });
  } else {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "Push Campaigns",
      icon2: "fa fa-lock",
      url: "/app/listWebPushCampaigns",
    });
  }

  if (hasEmail) {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "email campaigns",
      url: "/app/email/list",
    });
  } else {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "email campaigns",
      icon2: "fa fa-lock",
      url: "/app/email/list",
    });
  }

  if (hasWhatsApp) {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "whatsapp campaigns",
      url: "/app/whatsapp/list",
    });
  } else {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "whatsapp campaigns",
      icon2: "fa fa-lock",
      url: "/app/whatsapp/list",
    });
  }

  if (hasSMS && !isSalla) {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "SMS Campaigns",
      url: "/app/listSMSCampaigns",
    });
  } else if (!isSalla) {
    campaignChildren.push({
      key: uuidv4(),
      subMenuLevel: 1,
      title: "SMS Campaigns",
      icon2: "fa fa-lock",
      url: "/app/listSMSCampaigns",
    });
  }

  const items = [
    {
      slug: "home",
      title: "home",
      icon: "fa fa-home fa-lg",
      url: "/app/home",
    },
    {
      slug: "subscribers",
      title: "subscribers",
      icon: "fa fa-users fa-lg",
      url: "/app/subscribers",
    },
    {
      slug: "CRM",
      title: "CRM",
      icon: "fa fa-briefcase fa-lg",
      url: "/app/crm",
    },
    {
      slug: "segments",
      title: "segments",
      icon: "fa fa-tags fa-lg",
      url: "/app/segments",
    },
    {
      slug: "purchases",
      title: "purchases",
      icon: "fa fa-shopping-cart fa-lg",
      url: "/app/purchases",
    },
    hasAutomator
      ? {
          slug: "automator",
          title: "automated",
          icon: "fa fa-cogs fa-lg",
          url: "/app/automator",
        }
      : {
          slug: "automator",
          title: "automated",
          icon: "fa fa-cogs fa-lg",
          icon2: "fa fa-lock",
          url: "/app/automator",
        },
    {
      slug: "templates",
      title: "templates",
      icon: "fa fa-file-text-o fa-lg",
      url: "/app/templates",
    },
    {
      slug: "shortLinks",
      title: "short Links",
      icon: "fa fa-link fa-lg",
      url: "/app/shortLinks",
    },
  ];

  (isShopify || isZid || isSalla) &&
    items.push({
      slug: "products",
      title: "Products",
      key: uuidv4(),
      icon: "fa fa-shopping-cart",
      url: "/app/products",
    });

  if (hasMicroPage) {
    items.push({
      slug: "microPages",
      title: "Micro Pages",
      key: uuidv4(),
      icon: "fa fa-mobile fa-lg",
      url: "/app/microPages",
    });
  } else {
    items.push({
      slug: "microPages",
      title: "Micro Pages",
      key: uuidv4(),
      icon: "fa fa-mobile fa-lg",
      icon2: "fa fa-lock",
      url: "/app/microPages",
    });
  }

  items.push({
    slug: "CampaignsHeader",
    title: "Campaigns",
    icon: "fa fa-bullhorn fa-lg",
    key: uuidv4(),
    children: campaignChildren,
  });

  if (hasSMS && !isSalla) {
    items.push({
      slug: "smsTargetLists",
      title: "SMS Target Lists",
      key: uuidv4(),
      icon: "fa fa-list-ul",
      url: "/app/smsTargetLists",
    });
  } else if (!isSalla) {
    items.push({
      slug: "smsTargetLists",
      title: "SMS Target Lists",
      key: uuidv4(),
      icon: "fa fa-list-ul",
      icon2: "fa fa-lock",
      url: "/app/smsTargetLists",
    });
  }

  items.push({
    slug: "store",
    key: uuidv4(),
    title: "Stores",
    icon: "fa fa-database fa-lg",
    url: "/stores",
  });

  let user = JSON.parse(localStorage.getItem("user.data"));
  if ((hasAppBackend || isMobileMarketting) && !user.projects_products)
    items.push({
      slug: "setting",
      title: "Store Settings",
      icon: "fa fa-gear fa-lg",
      url: "/app/settings",
    });

  return items;
};
export default generateProductRoutes;
