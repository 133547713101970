import { Col, Form, PrimaryButton, Row, TextBox } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";
import classes from "../SMSConfig.scss";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import HelpButton from "../HelpButton";

export class VictoryLink extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;
    if (
      notify &&
      notify.channels.sms.connection_info.type === "SMS-VICTORYLINK" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.sms.vendor || undefined,
        type: "SMS-VICTORYLINK",
        UserName: notify.channels.sms.connection_info.UserName || undefined,
        Password: notify.channels.sms.connection_info.Password || undefined,
        SMSLang: notify.channels.sms.connection_info.SMSLang || undefined,
        SMSSender: notify.channels.sms.connection_info.SMSSender || undefined,
      });

      this.setState({ initialized: true });
    }
  }
  render() {
    const { valid, handleSubmit, disabled, handleBack, isArabic } = this.props;

    return (
      <Form>
        <div className={classes.contain}>
          <Row>
            <Col md={6}>
              <Field
                name="vendor"
                placeholder={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Name_placeholder"
                )}
                validate={required}
                label={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Name_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="UserName"
                placeholder={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Username_placeholder"
                )}
                validate={required}
                label={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Username_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="SMSLang"
                placeholder={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_SMSLang_placeholder"
                )}
                validate={required}
                label={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_SMSLang_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="Password"
                placeholder={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Password_placeholder"
                )}
                validate={required}
                label={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_Password_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Field
                name="SMSSender"
                placeholder={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_SMSSender_placeholder"
                )}
                validate={required}
                label={I18n.t(
                  "SettingsPage_CommunicationChannels_SMSConfig_option_VictoryLink_SMSSender_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <HelpButton />

          <div className="buttonsContainer">
            <button
              type="button"
              onClick={() => {
                handleBack();
              }}
              className="confirm cancel"
            >
              {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
              <span>{I18n.t("backBtn")}</span>
            </button>

            <button
              disabled={!valid}
              onClick={handleSubmit}
              className="confirm"
            >
              <span>{I18n.t("continueBtn")}</span>

              {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      getNotifyConfig,
    }
  )(VictoryLink)
);
