import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit } from "reducers/user";
import { upgrade } from "../../modules/upgrade";
import classes from "./LockedPage.scss";

class LockedPage extends React.Component {
  onClickUpgrade = () => {
    const { source, subdomain } = this.props;

    upgrade(this.context, source, subdomain);
  };

  render() {
    return (
      <section className={classes.lockedPage}>
        <span className={classes.breadcrumb}>
          {I18n.t(this.props.breadcrumb)}
        </span>
        <div className={classes.container}>
          <div className={classes.content}>
            <h1 className={classes.title}>{I18n.t(this.props.pageName)}</h1>
            <p className={classes.description}>
              {I18n.t(this.props.description)}
            </p>
            <PrimaryButton
              onClick={this.onClickUpgrade}
              icon={() => <i className="fa fa-unlock" />}
              className={classes.unlockButton}
              value={I18n.t("LockedPage_upgradeToUnlockBtn")}
            />
          </div>
          <div className={classes.imageContainer}>
            <img src={this.props.screenSrc} />
          </div>
        </div>
      </section>
    );
  }
}
LockedPage.contextTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  { getCurrentSuit }
)(LockedPage);
