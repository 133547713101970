import PropTypes from "prop-types";
import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import classes from "./EmptyStatePage.scss";

export default class EmptyStatePage extends Component {
  static propTypes = {
    pageTitle: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    CTATitle: PropTypes.string,
    CTAOnClick: PropTypes.func,
  };

  render() {
    const { pageTitle, image, title, subTitle, CTATitle, CTAOnClick } =
      this.props;

    return (
      <section className={classes.emptyStatePage}>
        <h1 className={classes.pageTitle}>{I18n.t(pageTitle)}</h1>

        <div className={classes.content}>
          <img
            src={image}
            alt={I18n.t(title)}
            width={400}
            height={300}
            className={classes.image}
          />
          <h2 className={classes.title}>{I18n.t(title)}</h2>
          <p className={classes.subTitle}>{I18n.t(subTitle)}</p>

          <button className={classes.CTAButton} onClick={CTAOnClick}>
            {I18n.t(CTATitle)}
          </button>
        </div>
      </section>
    );
  }
}
