import React from "react";
import { I18n } from "react-redux-i18n";
import footerLogo from "../../static/logos/shrink-it-footer-logo.png";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#630BDE" }}>
      <section
        style={{
          backgroundColor: "#630BDE",
          textAlign: "center",
          padding: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <img src={footerLogo} style={{ width: "7.5%", marginBottom: "15px" }} />
        <p style={{ color: "white" }}></p>
        <article style={{ display: "flex" }}>
          {["facebook"].map((icon) => (
            <a
              key={icon}
              href="https://www.facebook.com/profile.php?id=100077163408077"
              rel="noreferrer"
              target={"_blank"}
              style={{ textDecoration: "none" }}
            >
              <div
                key={icon}
                style={{
                  marginLeft: "7.5px",
                  padding: "3.5px",
                  background: "white",
                  borderRadius: "50%",
                  width: "35px",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className={`fa fa-${icon}`}
                  style={{ color: "#630BDE", fontSize: "22px" }}
                />
              </div>
            </a>
          ))}
        </article>
      </section>
      <section
        style={{
          padding: "6px",
          background: "#8A48E6",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          color: "white",
          width: "100%",
        }}
      >
        <p>{I18n.t("MadeBy")}</p>
        <p>{I18n.t("rights")}</p>
      </section>
    </footer>
  );
};

export default Footer;
