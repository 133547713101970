import { Icon } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

class Copyicon extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  copynotification() {
    const notificationOpts = {
      title: "",
      message: I18n.t("Copied"),
      position: "bc",
      autoDismiss: 5,
    };

    this.props.dispatch(Notifications.success(notificationOpts));
  }

  render() {
    const { value } = this.props;
    return (
      <CopyToClipboard text={value}>
        <a
          onClick={() => {
            this.copynotification();
          }}
        >
          {this.props.isRed ? (
            <i
              style={{ color: "#f22e52" }}
              className="fa fa-clone copyButton"
            />
          ) : (
            <Icon type="copy" theme="twoTone" twoToneColor="#630bde" />
          )}
        </a>
      </CopyToClipboard>
    );
  }
}
export default connect()(Copyicon);
