import User from "api/User";
import { STRING } from "consts/TableColumnTypes";
import _ from "lodash";
import {
  isLoggedIn,
  login,
  logout,
  setInitData,
  setUserSuits,
  updateUser,
  userData,
  userSuits,
} from "modules/user";

import { config } from "consts/api";

// ------------------------------------
// Constants
// ------------------------------------
export const RESET_ERRORS = "RESET_ERRORS";
const LOGIN = "LOGIN";
const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
const LOGOUT = "LOGOUT";
const SIGNUP = "SIGNUP";
const RECOVER_PASSWORD = "RECOVER_PASSWORD";
const RESET_PASSWORD = "RESET_PASSWORD";
const RESEND_CONFIRMATION = "RESEND_CONFIRMATION";
const KYC = "KYC";
const GET_USER_SUITS = "GET_USER_SUITS";
const GET_USER_PROFILE = "GET_USER_PROFILE";
const UPDATE_USER_PROFILE = "UPDATE_SUIT";
export const SET_ACTIVE_SUIT_ID = "SET_ACTIVE_SUIT_ID";
const CREATE_SUIT = "CREATE_SUIT";
const UPDATE_SUIT = "UPDATE_SUIT";
const UPDATE_MOBILEMARKITING = "UPDATE_MOBILEMARKITING";
const DELETE_SUIT = "DELETE_SUIT";
const GET_CURRENT_SUIT = "GET_CURRENT_SUIT";
const GET_CURRENT_SUIT_MOBILEMARKITING = "GET_CURRENT_SUIT_MOBILEMARKITING";
const REMOVE_CURRENT_SUIT = "REMOVE_CURRENT_SUIT";
const GET_INVOICES = "GET_INVOICES";
const SHOULD_LOG_OUT = "SHOULD_LOG_OUT";
const CLEAR_SHOULD_LOG_OUT = "CLEAR_SHOULD_LOG_OUT";
const SUBMIT_STRIPE_TOKEN = "SUBMIT_STRIPE_TOKEN";
const GENERATE_API_KEY = "GENERATE_API_KEY";
const DELETEUSER = "DELETEUSER";
const RESET_CREDIT = "RESET_CREDIT";
const ADDON = "ADDON";
const MEDIALIBRARY = "MEDIALIBRARY";
const DELETEMEDIALIBRARY = "DELETEMEDIALIBRARY";
const FILLDEMODATA = "FILLDEMODATA";
const CLEARDATA = "CLEARDATA";
const GET_APP_CURRENCY = "GET_APP_CURRENCY";
const CHECK_SUBSCRIBED = "CHECK_SUBSCRIBED";
const RESET_USER_ERRORS = "RESET_USER_ERRORS";
const DISABLE_TOUR_SLIDER = "DISABLE_TOUR_SLIDER";
const UPGRADE_PLAN = "UPGRADE_PLAN";
const ADD_SUB_USER = "ADD_SUB_USER";
const GET_SUB_USERS = "GET_SUB_USERS";
const EDIT_SUB_USER = "EDIT_SUB_USER";
const DELETE_SUB_USER = "DELETE_SUB_USER";
const SUB_USERS_DATA_TABLE_CHECK_ROW = "SUB_USERS_DATA_TABLE_CHECK_ROW";
const SUB_USERS_DATA_TABLE_COLUMN_TOGGLE = "SUB_USERS_DATA_TABLE_COLUMN_TOGGLE";
const SUB_USERS_DATA_TABLE_RESET_COLUMNS = "SUB_USERS_DATA_TABLE_RESET_COLUMNS";
const SUB_USERS_CHECK_ALL_DATA_TABLE_ROWS =
  "SUB_USERS_CHECK_ALL_DATA_TABLE_ROWS";

const SUB_USERS_TOGGLE_ROW_ACTION_MENU = "SUB_USERS_TOGGLE_ROW_ACTION_MENU";

const SUB_USERS_CLEAR_ACTION_MENU = "SUB_USERS_CLEAR_ACTION_MENU";

// ------------------------------------
// Dispatching Actions
// ------------------------------------

export function resetErrors() {
  return {
    type: RESET_ERRORS,
  };
}

export function resetUserError() {
  return {
    type: RESET_USER_ERRORS,
  };
}

export function deletUser(data, auth) {
  return {
    type: DELETEUSER,
    payload: User.deletUser(data, auth),
  };
}
export function handleLogin(data) {
  if (!config.AUTH_TOKEN) {
    setInitData(data);
  }

  return {
    type: LOGIN,
    payload: User.loginUser(data),
  };
}

// used in when login with google make login and get user data from local storage
export function loginWithGoogle() {
  return {
    type: LOGIN_WITH_GOOGLE,
  };
}

export function handleLogout() {
  logout();
  return {
    type: LOGOUT,
  };
}

export function upgradePlan(
  suit_id,
  plan_id,
  infra_type,
  coupon,
  subscription_type
) {
  return {
    type: UPGRADE_PLAN,
    payload: User.upgradePlan(
      suit_id,
      plan_id,
      infra_type,
      coupon,
      subscription_type
    ),
  };
}

export function handleSignUp(data) {
  return {
    type: SIGNUP,
    payload: User.registerUser(data),
  };
}

export function handleForgotPassword(data) {
  return {
    type: RECOVER_PASSWORD,
    payload: User.forgotPassword(data),
  };
}

export function handleResetPassword(data) {
  const token = data.token;
  delete data.token;
  return {
    type: RESET_PASSWORD,
    payload: User.resetPassword(data, token),
  };
}

export function handleResendConfirmation(email, data) {
  return {
    type: RESEND_CONFIRMATION,
    payload: User.resendConfirmation(email, data),
  };
}

export function handleKYCData(data, action) {
  return {
    type: KYC,
    payload: User.updateUserProfile(data, action),
  };
}

export function getUserSuits() {
  return {
    type: GET_USER_SUITS,
    payload: User.getUserSuits(),
  };
}

export function updateUserProfile(data, action) {
  return {
    type: UPDATE_USER_PROFILE,
    payload: User.updateUserProfile(data, action),
  };
}

export function getUserProfile() {
  return {
    type: GET_USER_PROFILE,
    payload: User.getUserProfile(),
  };
}

export function setActiveSuitId(suitId) {
  return {
    type: SET_ACTIVE_SUIT_ID,
    payload: suitId,
  };
}

export function getCurrentSuit(suitId) {
  return {
    type: GET_CURRENT_SUIT,
    payload: User.getCurrentSuit(suitId),
  };
}

export function getCurrentSuitmobilemarkiting(suitId) {
  return {
    type: GET_CURRENT_SUIT_MOBILEMARKITING,
    payload: User.getCurrentSuit(suitId),
  };
}

export function removeCurrentSuit() {
  return {
    type: REMOVE_CURRENT_SUIT,
  };
}

export function createSuit(data) {
  return {
    type: CREATE_SUIT,
    payload: User.createSuit(data),
  };
}

export function fillDemoData(suitId) {
  return {
    type: FILLDEMODATA,
    payload: User.FillDemoData(suitId),
  };
}

export function clearDemoData(suitId) {
  return {
    type: CLEARDATA,
    payload: User.ClearDemoData(suitId),
  };
}

export function updateSuit(suitId, data) {
  return {
    type: UPDATE_SUIT,
    payload: User.updateSuit(suitId, data),
  };
}

export function updateSuitmobilemarkiting(suitId, data) {
  return {
    type: UPDATE_MOBILEMARKITING,
    payload: User.updateSuit(suitId, data),
  };
}

export function deleteSuit(suitId) {
  return {
    type: DELETE_SUIT,
    payload: User.deleteSuit(suitId),
  };
}

export function getInvoices(customer) {
  return {
    type: GET_INVOICES,
    payload: User.getInvoices(customer),
  };
}
export function shouldLogOut() {
  return {
    type: SHOULD_LOG_OUT,
  };
}
export function clearShouldLogOut() {
  return {
    type: CLEAR_SHOULD_LOG_OUT,
  };
}
export function submitStripeToken(id, userId) {
  return {
    type: SUBMIT_STRIPE_TOKEN,
    payload: User.submitStripeToken(id, userId),
  };
}
export function generateAPIKey(data, appId) {
  return {
    type: GENERATE_API_KEY,
    payload: User.generateAPIKey(data, appId),
  };
}
export function resetCredit() {
  return {
    type: RESET_CREDIT,
  };
}

export function addon(suitId, apiKey, data) {
  return {
    type: ADDON,
    payload: User.addon(suitId, apiKey, data),
  };
}
export function MediaLibrary(suitId, apiKey) {
  return {
    type: MEDIALIBRARY,
    payload: User.MediaLibrary(suitId, apiKey),
  };
}

export function DeleteMedia(suitId, apiKey) {
  return {
    type: DELETEMEDIALIBRARY,
    payload: User.DeleteMedia(suitId, apiKey),
  };
}

export function checkSubscribed(serverURL, appId, suitId, masterKey, apiKey) {
  return {
    type: CHECK_SUBSCRIBED,
    payload: User.checkSubscribed(serverURL, appId, suitId, masterKey, apiKey),
  };
}

export function getAppCurrency(serverURL, appId, suitId, masterKey, apiKey) {
  return {
    type: GET_APP_CURRENCY,
    payload: User.getAppCurrency(serverURL, appId, suitId, masterKey, apiKey),
  };
}

export function disableTourSlider() {
  return {
    type: DISABLE_TOUR_SLIDER,
  };
}

// Sub users

export function addSubUser(data) {
  return {
    type: ADD_SUB_USER,
    payload: User.addSubUser(data),
  };
}

export function getSubUsers() {
  return {
    type: GET_SUB_USERS,
    payload: User.getSubUsers(),
  };
}

export function editSubUser(data) {
  return {
    type: EDIT_SUB_USER,
    payload: User.editSubUser(data),
  };
}

export function deleteSubUser(data) {
  return {
    type: DELETE_SUB_USER,
    payload: User.deleteSubUser(data),
  };
}

export function checkSubUserDataTableRow(idx) {
  return {
    type: SUB_USERS_DATA_TABLE_CHECK_ROW,
    payload: idx,
  };
}

export function onSubUserDataTableColumnToggle(idx) {
  return {
    type: SUB_USERS_DATA_TABLE_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onSubUserDataTableResetColumns() {
  return {
    type: SUB_USERS_DATA_TABLE_RESET_COLUMNS,
  };
}

export function checkAllSubUserDataTableRows(newChecked) {
  return {
    type: SUB_USERS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function toggleSubusersRowActionMenu(idx) {
  return {
    type: SUB_USERS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearSubUsersActionMenu(idx) {
  return {
    type: SUB_USERS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

function prepareSubUsersDataTable(tableData) {
  const labels = [
    {
      name: "Name",
      type: STRING,
      selected: true,
      varName: ["name"],
      defaultValue: "",
    },
    {
      name: "Email",
      type: STRING,
      selected: true,
      varName: ["email"],
      defaultValue: "",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    user_id: row.user_id,
    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
    ],
  }));

  return { labels, rows, actions: true };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  isLoggedIn: isLoggedIn(),
  data: userData(),
  suits: userSuits(),
  suit: null,
  credit: false,
  kyc: false,
  activeSuitId: false,
  appbackend: null,
  isPending: false,
  error: false,
  errorMessage: "",
  suit_id: null,
  invoices: null,
  shouldLogOut: false,
  mediaLibraryData: null,
  demoData: null,
  isSubscribed: null,
  currency: null,
  tourSlider: true,
  subUsers: null,
  subUsersDataTable: null,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function userReducer(state = initialState, action) {
  // handle token expiry
  if (action.type.includes("_REJECTED") && action.payload.status === 403) {
    return {
      ...state,
      isPending: false,
      error: action.payload,
      shouldLogOut: true,
    };
  }
  switch (action.type) {
    case RESET_ERRORS:
    case RESET_USER_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${LOGIN}_PENDING`:
    case `${SIGNUP}_PENDING`:
    case `${RECOVER_PASSWORD}_PENDING`:
    case `${RESET_PASSWORD}_PENDING`:
    case `${RESEND_CONFIRMATION}_PENDING`:
    case `${KYC}_PENDING`:
    case `${GET_USER_SUITS}_PENDING`:
    case `${GET_USER_PROFILE}_PENDING`:
    case `${UPDATE_USER_PROFILE}_PENDING`:
    case `${CREATE_SUIT}_PENDING`:
    case `${UPDATE_SUIT}_PENDING`:
    case `${UPDATE_MOBILEMARKITING}_PENDING`:
    case `${DELETE_SUIT}_PENDING`:
    case `${GET_CURRENT_SUIT}_PENDING`:
    case `${GET_CURRENT_SUIT_MOBILEMARKITING}_PENDING`:
    case `${REMOVE_CURRENT_SUIT}_PENDING`:
    case `${GET_INVOICES}_PENDING`:
    case `${SUBMIT_STRIPE_TOKEN}_PENDING`:
    case `${GENERATE_API_KEY}_PENDING`:
    case `${ADDON}_PENDING`:
    case `${DELETEUSER}_PENDING`:
    case `${MEDIALIBRARY}_PENDING`:
    case `${DELETEMEDIALIBRARY}_PENDING`:
    case `${FILLDEMODATA}_PENDING`:
    case `${CLEARDATA}_PENDING`:
    case `${CHECK_SUBSCRIBED}_PENDING`:
    case `${ADD_SUB_USER}_PENDING`:
    case `${EDIT_SUB_USER}_PENDING`:
    case `${DELETE_SUB_USER}_PENDING`:
    case `${GET_SUB_USERS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${LOGIN}_REJECTED`:
      if (action.payload.status === 406) {
        action.type = `${LOGIN}_FULFILLED`;
        login(action.payload.response.body);
        return {
          ...state,
          isLoggedIn: true,
          isPending: false,
          kyc: true,
          data: userData(),
        };
      } else if (action.payload.status === 402) {
        login(action.payload.response.body);
        return {
          ...state,
          isLoggedIn: true,
          isPending: false,
          credit: true,
          data: userData(),
        };
      } else {
        return {
          ...state,
          isPending: false,
          error: action.payload,
        };
      }

    // 400 projects number maximum exceeded => max 25 suit || subdomain is invalid
    // 402 failed
    // 404 plan not exists
    // 406 only 1 free project per account || plan not support || subscription in this plan is invalid
    // 409 subdomain is taken || domain is taken || project name is taken

    case `${CREATE_SUIT}_REJECTED`:
      if (action.payload.status === 402) {
        return {
          ...state,
          isPending: false,
          credit: true,
          error: action.payload,
          errorMessage: action.payload.response.body.message,
        };
      } else if (action.payload.status === 406) {
        return {
          ...state,
          isPending: false,
          errorMessage: action.payload.response.body.message,
        };
      } else if (action.payload.status === 409) {
        return {
          ...state,
          isPending: false,
          error: action.payload,
        };
      } else {
        return {
          ...state,
          isPending: false,
          error: action.payload,
        };
      }

    case `${SIGNUP}_REJECTED`:
    case `${RECOVER_PASSWORD}_REJECTED`:
    case `${RESET_PASSWORD}_REJECTED`:
    case `${RESEND_CONFIRMATION}_REJECTED`:
    case `${KYC}_REJECTED`:
    case `${GET_USER_SUITS}_REJECTED`:
    case `${GET_USER_PROFILE}_REJECTED`:
    case `${UPDATE_USER_PROFILE}_REJECTED`:
    case `${UPDATE_MOBILEMARKITING}_REJECTED`:
    case `${DELETE_SUIT}_REJECTED`:
    case `${GET_CURRENT_SUIT}_REJECTED`:
    case `${GET_CURRENT_SUIT_MOBILEMARKITING}_REJECTED`:
    case `${REMOVE_CURRENT_SUIT}_REJECTED`:
    case `${SUBMIT_STRIPE_TOKEN}_REJECTED`:
    case `${GENERATE_API_KEY}_REJECTED`:
    case `${ADDON}_REJECTED`:
    case `${DELETEUSER}_REJECTED`:
    case `${MEDIALIBRARY}_REJECTED`:
    case `${DELETEMEDIALIBRARY}_REJECTED`:
    case `${FILLDEMODATA}_REJECTED`:
    case `${CLEARDATA}_REJECTED`:
    case `${ADD_SUB_USER}_REJECTED`:
    case `${EDIT_SUB_USER}_REJECTED`:
    case `${DELETE_SUB_USER}_REJECTED`:
    case `${GET_SUB_USERS}_REJECTED`:
    case `${CHECK_SUBSCRIBED}_REJECTED`:
      return {
        ...state,
        isPending: false,
        isSubscribed: false,
        error: action.payload,
      };

    case `${LOGIN}_FULFILLED`:
      login(action.payload.body);
      return {
        ...state,
        isLoggedIn: true,
        isPending: false,
        data: userData(),
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isPending: false,
        data: null,
        suits: null,
        shouldLogOut: false,
      };

    case `${GET_USER_SUITS}_FULFILLED`:
      setUserSuits(action.payload.body.user_suits);
      return {
        ...state,
        isPending: false,
        suits: action.payload.body.user_suits,
      };
    case `${SUBMIT_STRIPE_TOKEN}_FULFILLED`:
    case `${GET_USER_PROFILE}_FULFILLED`:
      updateUser({ ...userData(), ...action.payload.body.user });
      login(action.payload.body);
      return {
        ...state,
        isPending: false,
        data: userData(),
      };
    case `${SIGNUP}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${UPDATE_SUIT}_FULFILLED`:
    case `${UPDATE_MOBILEMARKITING}_FULFILLED`:
    case `${DELETE_SUIT}_FULFILLED`:
    case `${RECOVER_PASSWORD}_FULFILLED`:
    case `${RESET_PASSWORD}_FULFILLED`:
    case `${RESEND_CONFIRMATION}_FULFILLED`:
    case `${KYC}_FULFILLED`:
    case `${UPDATE_USER_PROFILE}_FULFILLED`:
    case `${GENERATE_API_KEY}_FULFILLED`:
    case `${DELETEUSER}_FULFILLED`:
    case `${FILLDEMODATA}_FULFILLED`:
    case `${CLEARDATA}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${CREATE_SUIT}_FULFILLED`:
      return {
        ...state,
        suit_id: action.payload.body.suit_id,
        isPending: false,
      };
    case `${GET_CURRENT_SUIT}_FULFILLED`:
    case `${GET_CURRENT_SUIT_MOBILEMARKITING}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        suit: { ...state.suit, ...action.payload.body },
      };
    case `${GET_INVOICES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        invoices: action.payload.body.data,
      };
    case `${MEDIALIBRARY}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mediaLibraryData: action.payload.body,
      };

    case `${CHECK_SUBSCRIBED}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        isSubscribed: action.payload.body.result,
      };

    case `${DELETEMEDIALIBRARY}_FULFILLED`:
    case REMOVE_CURRENT_SUIT:
      return {
        ...state,
        suit: null,
        error: false,
        isSubscribed: null,
      };
    case SET_ACTIVE_SUIT_ID:
      return {
        ...state,
        activeSuitId: action.payload,
      };
    case RESET_CREDIT:
      return {
        ...state,
        credit: false,
      };

    case `${ADDON}_FULFILLED`:
      return {
        ...state,

        isPending: false,
      };
    case LOGIN_WITH_GOOGLE:
      return {
        ...state,
        isLoggedIn: true,
        data: userData(),
      };

    case `${GET_APP_CURRENCY}_FULFILLED`:
      return {
        ...state,
        currency: action.payload.body.result,
      };

    case DISABLE_TOUR_SLIDER:
      return {
        ...state,
        tourSlider: false,
      };

    case `${UPGRADE_PLAN}_PENDING`:
      return {
        ...state,
        isPending: true,
      };
    case `${UPGRADE_PLAN}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${UPGRADE_PLAN}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${ADD_SUB_USER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        subUsers: [...state.subUsers, action.payload.body.sub_user],
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: [
            ...state.subUsersDataTable.rows,
            {
              checked: false,
              actionsMenu: false,
              user_id: action.payload.body.sub_user.user_id,
              values: [
                action.payload.body.sub_user.name,
                action.payload.body.sub_user.email,
              ],
            },
          ],
        },
      };
    case `${GET_SUB_USERS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        subUsers: action.payload.body.custom_users,
        subUsersDataTable: prepareSubUsersDataTable(
          action.payload.body.custom_users
        ),
      };
    case `${EDIT_SUB_USER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        subUsers: state.subUsers.map((subuser) =>
          subuser.user_id === action.payload.body.sub_user.user_id
            ? action.payload.body.sub_user
            : subuser
        ),
      };
    case `${DELETE_SUB_USER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        subUsers: state.subUsers.filter(
          (subuser) => subuser.user_id !== action.payload.body.sub_user.user_id
        ),
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: state.subUsersDataTable.rows.filter(
            (row) => row.user_id !== action.payload.body.sub_user.user_id
          ),
        },
      };

    case SUB_USERS_DATA_TABLE_CHECK_ROW:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: state.subUsersDataTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.subUsersDataTable.rows[rowIdx],
                  checked: !state.subUsersDataTable.rows[rowIdx].checked,
                }
              : row;
          }),
        },
      };
    case SUB_USERS_DATA_TABLE_COLUMN_TOGGLE:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          labels: state.subUsersDataTable.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case SUB_USERS_DATA_TABLE_RESET_COLUMNS:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          labels: state.subUsersDataTable.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SUB_USERS_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: state.subUsersDataTable.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case SUB_USERS_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: state.subUsersDataTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.subUsersDataTable.rows[rowIdx],
                  actionsMenu:
                    !state.subUsersDataTable.rows[rowIdx].actionsMenu,
                }
              : row;
          }),
        },
      };
    case SUB_USERS_CLEAR_ACTION_MENU:
      return {
        ...state,
        subUsersDataTable: {
          ...state.subUsersDataTable,
          rows: state.subUsersDataTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.subUsersDataTable.rows[rowIdx],
                  actionsMenu: false,
                }
              : row;
          }),
        },
      };
    default:
      return state;
  }
}
