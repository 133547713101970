/**
 * Upgrade function to redirect user to the appropriate upgrade plan based on the source.
 * @param {Object} context - The context object.
 * @param {string} source - The source of the upgrade plan.
 * @param {string} subdomain - The subdomain of the user.
 * @param {Object} router - The router object.
 */
export const upgrade = (context, source, subdomain, router) => {
  if (source === "Shopify") {
    router
      ? router.push(`/app/${subdomain}/shopifyUpgradePlan`)
      : context.history.push(`/app/${subdomain}/shopifyUpgradePlan`);
  } else if (source === "Zid") {
    window.location.href = "https://web.zid.sa/market/app/1912#PricesAndPlans";
  } else if (source === "Salla") {
    window.location.href = "https://apps.salla.sa/ar/app/1807794781";
  } else {
    router
      ? router.push(`/app/${subdomain}/upgrade-plan`)
      : context.history.push(`/app/${subdomain}/upgrade-plan`);
  }
};
