import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import Select from "react-select";
import classes from "./CustomSelectorReduxForm.scss";

const CustomSelectorReduxForm = ({
  input,
  options,
  meta,
  label,
  style,
  onChange,
  ...rest
}) => {
  return (
    <div className={rest.className} style={style}>
      {label && <label className={classes.label}>{label}</label>}
      <Select
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              fontFamily: "Darker Grotesque",
              borderColor: state.isFocused ? "#630bde" : "#bbb",
              ...(rest.height ? { height: rest.height } : { height: "48px" }),
              border: "1px solid #bbb",
              backgroundColor: "#FFFFFF",
              borderRadius: "4px",
              boxShadow: "none",
              "&:focus, &:focus-within, &:focus:active": {
                border: "1px solid #630bde",
                backgroundColor: "#3f02a119",
              },
              "&:hover": {
                border: "1px solid #bbb",
              },
              transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            };
          },
          input: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Darker Grotesque",
              fontWeight: "700",
              fontSize: "15px",
              color: "#1f2f3f",
              transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            };
          },
          singleValue: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Darker Grotesque",
              fontWeight: "700",
              fontSize: "15px",
              color: "#1f2f3f",
              "&:focus, &:active, &:focus-withing": {
                color: "#1f2f3f",
              },
            };
          },
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        onChange={(value) => {
          input.onChange(value);
          onChange && onChange(value); // Call the onChange prop if it exists
        }}
        onBlur={() => input.onBlur(input.value)}
        defaultValue={meta?.initial}
        value={input?.value}
        placeholder={rest.placeholder || I18n.t("select")}
        menuPlacement="auto" // Set the menuPlacement prop to 'auto'
        isLoading={rest.loading || false}
      />
    </div>
  );
};

export default CustomSelectorReduxForm;

CustomSelectorReduxForm.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  meta: PropTypes.object,
  label: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};
