import { Switch } from "antd";
import React, { Component } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { I18n } from "react-redux-i18n";
import classes from "./ConfigureAutomators.module.scss";

const AUTOMATORS_LIST = [
  {
    id: 1,
    name: "automator_onSignUp",
  },
  {
    id: 2,
    name: "automator_abandonedCart",
  },
  {
    id: 3,
    name: "automator_orderConfirmation",
  },
  {
    id: 4,
    name: "automator_automationOverEmailSmsWhatsApp",
  },
];

class ConfigureAutomators extends Component {
  render() {
    const { handleSteps, handleBack, isArabic, isSalla } = this.props;
    return (
      <div
        className={classes.configureAutomators}
        dir={isArabic ? "rtl" : "ltr"}
      >
        <p className={classes.description}>
          {I18n.t("onBoarding_automators_desc")}
        </p>

        <div className={classes.automatorsList}>
          {AUTOMATORS_LIST.map((automator) => (
            <div key={automator.id} className={classes.automatorRow}>
              <span className={classes.automatorName}>
                {I18n.t(automator.name)}
              </span>
              <Switch className={classes.automatorSwitch} />
            </div>
          ))}
        </div>

        <div className="buttonsContainer">
          <button
            onClick={() => {
              handleBack();
            }}
            className="confirm cancel"
          >
            {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
            <span>{I18n.t("backBtn")}</span>
          </button>
          {!isSalla && (
            <button
              onClick={() => {
                handleSteps();
              }}
              className="confirm"
            >
              <span>{I18n.t("continueBtn")}</span>
              {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default ConfigureAutomators;
