import { Radio } from "antd";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import SMSvendor from "static/SMS Vendor.svg";
import SMSpackage from "static/SMS package .svg";
import classes from "../OnBoardingModal.scss";
import SMSPackage from "./SMSPackage";
import SMSVendor from "./SMSVendor";

const smsCards = [
  {
    image: SMSpackage,
    title: "onBoarding_SMS_card1_title",
    description: "onBoarding_SMS_card1_desc",
  },
  {
    image: SMSvendor,
    title: "onBoarding_SMS_card2_title",
    description: "onBoarding_SMS_card2_desc",
  },
];

class SMS extends React.Component {
  state = {
    country: "Saudi",
    package: "",
    value: "",
    TypeSMS: "",
    choosenType: "",
  };

  onChange = (e) => {
    this.setState({ TypeSMS: e.target.value });
  };

  handleSMSClick = () => {
    this.setState({ choosenType: this.state.TypeSMS });
    this.props.handleChosenSMSType(this.state.TypeSMS);
    this.props.handleSMSType(this.state.TypeSMS);
    this.props.handleProgress();
  };
  handleBackSMS = () => {
    this.setState({ choosenType: "" });
    this.props.handleChosenSMSType("");

    if (
      this.props.user?.suit?.source == "Zid" ||
      this.props.user?.suit?.source == "Salla"
    ) {
      this.props.handleStepNum();
    } else {
      this.setState({ choosenType: "" });
      this.props.handleChosenSMSType("");
    }
  };

  render() {
    const { TypeSMS } = this.state;
    const { handleSteps, handleBack, handleOnboardingSteps, isArabic } =
      this.props;
    return (
      <div className={classes.SMSPackage}>
        {/* cards */}
        {this.props.chosenSMSType == "" && (
          <div className={classes.SMSContainer}>
            <Radio.Group onChange={this.onChange} value={this.state.value}>
              {smsCards.map((sms, index) => {
                return (
                  <Radio
                    key={index}
                    onChange={this.onChange}
                    value={I18n.t(sms.title)}
                    className={`${classes.smsCard} smsCard ${
                      TypeSMS == I18n.t(sms.title)
                        ? `ant-radio-wrapper-checked`
                        : ""
                    }`}
                  >
                    <div className={classes.smsContent}>
                      <div>
                        <img
                          src={sms.image}
                          alt={I18n.t(sms.title)}
                          className=""
                        />
                      </div>
                      <div>
                        <h5>{I18n.t(sms.title)}</h5>
                        <p>{I18n.t(sms.description)}</p>
                      </div>
                    </div>
                  </Radio>
                );
              })}
            </Radio.Group>
            <div className="buttonsContainer">
              <button
                onClick={() => {
                  handleBack();
                }}
                className="confirm cancel"
              >
                {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
                {I18n.t("backBtn")}
              </button>

              <button
                onClick={() => {
                  this.handleSMSClick();
                }}
                className="confirm"
              >
                {I18n.t("continueBtn")}
                {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
              </button>
            </div>
          </div>
        )}

        {/* SMS Package Purchase */}

        {this.props.chosenSMSType === I18n.t("onBoarding_SMS_card1_title") && (
          <SMSPackage
            isArabic={isArabic}
            handleOnboardingSteps={handleOnboardingSteps}
            handleSteps={handleSteps}
            handleBack={this.handleBackSMS}
          />
        )}
        {this.props.chosenSMSType === I18n.t("onBoarding_SMS_card2_title") && (
          <SMSVendor
            isArabic={isArabic}
            handleOnboardingSteps={handleOnboardingSteps}
            handleSteps={handleSteps}
            handleBack={this.handleBackSMS}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    showNotification: Notifications.show,
  }
)(SMS);
