import { CustomSelector, SpinLoader } from "components";
import PropTypes from "prop-types";
import React from "react";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { getCurrentSuit } from "reducers/user";
import classes from "../OnBoardingModal.scss";
import AWS_SMS from "./SMSConfig/components/AWS_SMS";
import Alfa from "./SMSConfig/components/Alfa";
import CEQUENS from "./SMSConfig/components/CEQUENS";
import SMS_Country from "./SMSConfig/components/SMS_Country";
import SMS_Global from "./SMSConfig/components/SMS_Global";
import SMS_Jormail from "./SMSConfig/components/SMS_Jormail";
import SmsMisr from "./SMSConfig/components/SmsMisr";
import Twilio from "./SMSConfig/components/Twilio";
import UNIFONIC from "./SMSConfig/components/UNIFONIC";
import VictoryLink from "./SMSConfig/components/VictoryLink";

const SMSOptions = [
  {
    value: "AWS-SMS",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_1"),
  },
  {
    value: "SMSGlobal",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_2"),
  },
  {
    value: "Unifonic",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_3"),
  },
  {
    value: "VictoryLink",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_4"),
  },
  {
    value: "Twilio",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_5"),
  },
  {
    value: "Alfa",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_6"),
  },
  {
    value: "CEQUENS",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_7"),
  },
  {
    value: "SMS-JORMAIL",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_8"),
  },
  {
    value: "SMS_Country",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_9"),
  },
  {
    value: "SMS-MISR",
    label: I18n.t("SettingsPage_CommunicationChannels_SMSConfig_option_10"),
  },
];

class SMSConfig extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      requires_auth: false,
      requires_authVendors: false,
      disabled: true,
      TypeSMS: "AWS-SMS",
      validateSelect: false,
    };
  }

  async componentWillMount() {
    const {
      config: { notify },
      user: {
        data: { suits_list },
      },
      getNotifyConfig,
    } = this.props;

    if (notify && notify.channels && notify.channels.sms) return;

    await getNotifyConfig(suits_list[0]);
  }

  handleSave = () => {
    this.setState({
      disabled: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      setNotifyConfig,
      getNotifyConfig,
      form,
      user: {
        data: { suits_list },
      },
    } = this.props;

    const { TypeSMS } = this.state;
    const values = form.nconfig.values;
    let connectionInfo;

    switch (TypeSMS) {
      case "VictoryLink":
        connectionInfo = {
          connection_info: {
            type: "SMS-VICTORYLINK",
            UserName: values.UserName,
            Password: values.Password,
            SMSLang: values.SMSLang,
            SMSSender: values.SMSSender,
          },
        };
        break;
      case "Twilio":
        connectionInfo = {
          connection_info: {
            type: "SMS-TWILIO",
            account_sid: values.account_sid,
            sender: values.sender,
            auth_token: values.auth_token,
          },
        };
        break;

      case "SMS-MISR":
        connectionInfo = {
          connection_info: {
            type: "SMS-MISR",
            username: values.username,
            password: values.password,
            sender: values.sender,
          },
        };
        break;

      case "Alfa":
        connectionInfo = {
          connection_info: {
            type: "SMS-ALFA",
            apiKey: values.apiKey,
            sender: values.sender,
          },
        };
        break;

      case "CEQUENS":
        connectionInfo = {
          connection_info: {
            type: "SMS-CEQUENS",
            accesstoken: values.accesstoken,
            senderName: values.senderName,
          },
        };
        break;

      case "UNIFONIC":
        connectionInfo = {
          connection_info: {
            type: "SMS-UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "AWS-SMS":
        connectionInfo = {
          connection_info: {
            type: "AWS-SNS",
            access_key_ID: values.sms_access_key_ID,
            aws_region: values.sms_aws_region,
            secret_access_key: values.sms_secret_access_key,
          },
        };
        break;

      case "SMSGlobal":
        connectionInfo = {
          connection_info: {
            type: "HTTP_GET",
            url: values.url,
          },
        };
        break;

      case "Unifonic":
        connectionInfo = {
          connection_info: {
            type: "UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "SMS_Country":
        connectionInfo = {
          connection_info: {
            type: "SMS-COUNRTY",
            User: values.User,
            passwd: values.passwd,
          },
        };
        break;

      case "SMS-JORMAIL":
        connectionInfo = {
          connection_info: {
            type: "SMS-JORMAIL",
            UserName: values.UserName,
            Password: values.Password,
            SenderID: values.SenderID,
          },
        };
        break;

      default:
        break;
    }

    let conInfoClone = { ...connectionInfo.connection_info };

    for (let key in conInfoClone) {
      if (
        typeof conInfoClone[key] === "string" &&
        conInfoClone[key].includes("**")
      ) {
        delete conInfoClone[key];
      }
    }

    connectionInfo.connection_info = conInfoClone;

    let notifyConfigData = {
      channels: {
        sms: {
          ...connectionInfo,
          vendor: values.vendor,
        },
      },
    };

    setNotifyConfig(suits_list[0], notifyConfigData)
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_updateMsg"
            ),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        getNotifyConfig(suits_list[0]);
        this.props.handleOnboardingSteps("sms", "vendor");
      })
      .then(() => {
        this.handleSave();
      })
      .then(() => {
        this.props.handleSteps();
      });
  };

  handleModify = (type, value = false) => {
    if (value) {
      this.setState({ requires_auth: true });

      this.props.change("requires_authVendors", true);
    }
    if (!value) {
      this.setState({ requires_auth: false });
      this.props.change("requires_authVendors", false);
    }
  };

  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };

  handleSelectSMS = (newValue) => {
    this.setState({ TypeSMS: newValue.value });
  };

  render() {
    const { TypeSMS, validateSelect } = this.state;

    const {
      config: { notify, isPending },
      handleBack,
      isArabic,
    } = this.props;

    if (notify?.channels?.sms && !validateSelect) {
      const smstype = notify.channels.sms.connection_info?.type;
      const smstypeMap = {
        HTTP_GET: "SMSGlobal",
        "AWS-SNS": "AWS-SMS",
        UREST: "Unifonic",
        "SMS-CEQUENS": "CEQUENS",
        "SMS-VICTORYLINK": "VictoryLink",
        "SMS-TWILIO": "Twilio",
        "SMS-ALFA": "Alfa",
        "SMS-JORMAIL": "SMS-JORMAIL",
        "SMS-MISR": "SMS-MISR",
      };

      const TypeSMS = smstypeMap[smstype] || "SMS_Country";

      this.setState({ TypeSMS, validateSelect: true });
    }

    return (
      <div className={classes.SMSPackage}>
        {isPending && <SpinLoader />}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label
            style={{
              textAlign: "unset",
            }}
          >
            {I18n.t("SettingsPage_CommunicationChannels_SMSConfig")}
          </label>
          <CustomSelector
            style={{
              width: "100%",
            }}
            options={SMSOptions}
            value={SMSOptions.find((option) => option.value === TypeSMS)}
            onChange={this.handleSelectSMS}
          />
        </div>

        {TypeSMS === "VictoryLink" && (
          <VictoryLink
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "SMS-MISR" && (
          <SmsMisr
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "Alfa" && (
          <Alfa
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "Twilio" && (
          <Twilio
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "CEQUENS" && (
          <CEQUENS
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "AWS-SMS" && (
          <AWS_SMS
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}

        {TypeSMS === "SMSGlobal" && (
          <SMS_Global
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "Unifonic" && (
          <UNIFONIC
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
        {TypeSMS === "SMS_Country" && (
          <SMS_Country
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}

        {TypeSMS === "SMS-JORMAIL" && (
          <SMS_Jormail
            isArabic={isArabic}
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
            handleBack={handleBack}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    form: store.form,
  }),
  {
    setNotifyConfig,
    getNotifyConfig,
    getCurrentSuit,

    showNotification: Notifications.show,
  }
)(SMSConfig);

SMSConfig.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleOnboardingSteps: PropTypes.func.isRequired,
  handleSteps: PropTypes.func.isRequired,
  isArabic: PropTypes.bool.isRequired,
  showNotification: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setNotifyConfig: PropTypes.func.isRequired,
  getNotifyConfig: PropTypes.func.isRequired,
  getCurrentSuit: PropTypes.func.isRequired,
};
