import React from "react";
import classes from "./SMSConfig.scss";
import { I18n } from "react-redux-i18n";
import { AiOutlineQuestionCircle } from "react-icons/ai";
const HelpButton = () => {
  function openWidget() {
    window.fcWidget.open();
  }

  return (
    <div className={classes.helpButton}>
      <AiOutlineQuestionCircle size={32} color="#A18436" />
      <p>{I18n.t("Forhelppress")}</p>
      <button
        type="button"
        onClick={() => {
          openWidget();
        }}
      >
        {I18n.t("Here")}
      </button>
    </div>
  );
};
export default HelpButton;
