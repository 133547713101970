import { Modal } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getMobileAppPlans } from "reducers/Select";
import { getCurrentSuit, getUserSuits, updateSuit } from "reducers/user";
import { connect } from "routes/routedComponent";
import BuySMSPackageorUseYourVendor from "static/Buy SMS Package or Use Your Vendor.svg";
import createِAutomatorsProduct from "static/automator-onboarding.svg";
import configureretargetingchannels from "static/configure retargeting channels.svg";
import createshortlinksforallproducts from "static/create short links for all products.svg";
import progress from "static/progress.svg";
import success from "static/success.svg";
import Charged from "./Charged";
import Complete from "./Complete";
import ConfigureAutomators from "./ConfigureAutomators";
import classes from "./OnBoardingModal.scss";
import Retargeting from "./Retargeting";
import SMS from "./SMS/SMS";
import ShortLinks from "./ShortLinks";

class OnBoardingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepNum: 0,
      visible: false,
      smsType: "",
      progress: true,
      chosenSMSType: "",
      onboarding: {
        retargeting: false,
        smartLinks: false,
        automators: false,
        sms: {
          package: false,
          vendor: false,
        },
      },
      metaStatus: {},
    };

    const {
      user: { suits, activeSuitId },
    } = props;

    this.currentSuit = suits?.find((suit) => suit.id === activeSuitId);
    this.bodyElement = document.querySelector("body");
  }

  async componentDidMount() {
    const {
      user: { activeSuitId },
      getCurrentSuit,
    } = this.props;

    if (!activeSuitId) return console.log("no active suit id");
    const res = await getCurrentSuit(activeSuitId);
    const currentSuit = res.value.body;
    this.currentSuit = currentSuit;

    const { meta, source } = currentSuit;
    const { onboarding, sms } = meta || {};

    if (!meta || isEmpty(meta)) {
      this.showModal();
    } else {
      if (onboarding?.retargeting) {
        this.setState({ stepNum: 1 });
      }

      if (onboarding?.smartLinks) {
        this.setState({ stepNum: 2 });
        if (source === "Zid") {
          this.setState({ smsType: "Use Your SMS Vendor" });
          this.handleChosenSMSType(I18n.t("onBoarding_SMS_card2_title"));
        }
        if (source === "Salla") {
          this.setState({ stepNum: 3 });
        }
      }

      if (onboarding?.automators) {
        this.setState({ stepNum: 3 });
      }

      if (sms?.package || sms?.vendor) {
        this.setState({ stepNum: 4 });
      }
    }
  }

  handleSMSType = (value) => {
    this.setState({ smsType: value });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleProgress = () => {
    this.setState({ progress: false });
  };

  handleCancel = () => {
    this.bodyElement.style.overflow = "auto";
    this.setState({ visible: false });
  };

  handleBack = () => {
    if (this.state.stepNum != 0) {
      this.setState((prevState) => ({
        stepNum: Number(prevState.stepNum - 1),
      }));
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  handleSteps = () => {
    if (this.state.stepNum === 1) {
      if (this.props.user?.suit?.source == "Salla") {
        this.setState({ stepNum: 2 });
      }
    }
    if (this.state.stepNum != 3) {
      this.setState((prevState) => ({
        stepNum: Number(prevState.stepNum + 1),
      }));
    }

    if (this.state.stepNum === 1) {
      if (this.props.user?.suit?.source == "Zid") {
        this.setState({ smsType: "Use Your SMS Vendor" });
        this.handleChosenSMSType(I18n.t("onBoarding_SMS_card2_title"));
      }
    }
  };

  handleStepNum = () => {
    this.setState({ stepNum: 1 });
  };

  handleChosenSMSType = (type) => {
    this.setState({ chosenSMSType: type });
  };

  handleOnboardingSteps = (step, type, store) => {
    if (store == "Zid") {
      this.setState({ smsType: "Use Your SMS Vendor" });
      this.handleChosenSMSType(I18n.t("onBoarding_SMS_card2_title"));
    }
    if (type) {
      this.setState(
        (prevState) => {
          return {
            onboarding: {
              ...prevState.onboarding,
              sms: {
                ...prevState.onboarding.sms,
                [type]: true,
              },
            },
          };
        },
        () => {
          this.updateSteps();
        }
      );
    } else {
      this.setState(
        (prevState) => {
          return {
            onboarding: {
              ...prevState.onboarding,
              [step]: true,
            },
          };
        },
        () => {
          this.updateSteps();
        }
      );
    }
  };

  updateSteps = () => {
    const { updateSuit } = this.props;

    const { name, id } = this.currentSuit;

    updateSuit(id, {
      meta: { onboarding: this.state.onboarding },
      suit_name: name,
    });
  };

  handleRestart = async () => {
    // clear the onboarding steps from the suit
    const { updateSuit } = this.props;

    const { name, id } = this.currentSuit;

    try {
      await updateSuit(id, {
        meta: {},
        suit_name: name,
      });

      this.setState({ stepNum: 0 });

      this.showModal();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { stepNum, smsType, onboarding, visible } = this.state;

    const { isArabic, logo } = this.props;

    const suit = this.props.user?.suit;
    const suitSource = suit?.source;
    const isSalla = suitSource === "Salla";

    const onboardingModalData = [
      {
        image: configureretargetingchannels,
        title: I18n.t("onBoarding_integration_title"),
        step: "step1",
        body: Retargeting,
      },
      {
        image: createshortlinksforallproducts,
        title: I18n.t("onBoarding_links_title"),
        step: "step2",
        body: ShortLinks,
      },
      {
        image: createِAutomatorsProduct,
        title: I18n.t("onBoarding_automators_title"),
        step: "step3",
        body: ConfigureAutomators,
      },
      {
        image: BuySMSPackageorUseYourVendor,
        title: smsType ? (
          <div>
            {smsType === "Use Your SMS Vendor"
              ? I18n.t("onBoarding_SMS_vendor_title")
              : I18n.t("onBoarding_SMS_package_title")}
          </div>
        ) : (
          I18n.t("onBoarding_SMS_title")
        ),

        step: "step4",
        body: SMS,
      },
      {
        image: success,
        title: isSalla
          ? I18n.t("onBoarding_complete_vendor_desc")
          : onboarding?.sms?.vendor === true
          ? I18n.t("onBoarding_complete_vendor_title")
          : I18n.t("onBoarding_complete_package_title"),
        body: onboarding?.sms?.vendor === true ? Complete : Charged,
      },
    ];

    const { router } = this.context;

    const image = onboardingModalData[stepNum].image;
    const title = onboardingModalData[stepNum].title;
    const step = onboardingModalData[stepNum].step;
    const Body = onboardingModalData[stepNum].body;

    return (
      <div
        style={{
          direction: isArabic ? "rtl" : "ltr",
        }}
      >
        <button
          style={{
            direction: isArabic ? "rtl" : "ltr",
          }}
          onClick={this.showModal}
          className={classes.onboardingBtn}
        >
          <img
            src={progress}
            alt="progress"
            style={{ width: "24px", height: "24px" }}
          />

          {logo === "initial" ? "" : I18n.t("onBoardingBtn")}

          {logo === "initial" && isArabic && <MdKeyboardArrowLeft size={34} />}

          {logo === "initial" && isArabic && <MdKeyboardArrowRight size={34} />}
        </button>
        <Modal
          style={{
            direction: isArabic ? "rtl" : "ltr",
          }}
          className={`${classes.onBoadringModal} onBoadringModal`}
          title={
            <div>
              <div className="header">
                <img src={image} />
                <p>{title}</p>
              </div>
              {step && stepNum <= 2 && (
                <div className={classes.steps}>
                  <div className={classes.line}></div>

                  <div
                    className={`${classes.step} ${
                      step === "step1" || step === "step2" || step === "step3"
                        ? `${classes.active}`
                        : ``
                    }`}
                  >
                    1
                  </div>
                  <div
                    className={`${classes.step} ${
                      stepNum >= 1 || step === "step2"
                        ? `${classes.active}`
                        : ``
                    }`}
                  >
                    2
                  </div>
                  <div
                    className={`${classes.step} ${
                      stepNum >= 2 || step === "step3"
                        ? `${classes.active}`
                        : ``
                    }`}
                  >
                    3
                  </div>
                  {!isSalla && (
                    <div
                      className={`${classes.step} ${
                        stepNum <= 2 && step === "step4"
                          ? `${classes.active}`
                          : ``
                      }`}
                    >
                      4
                    </div>
                  )}
                </div>
              )}
            </div>
          }
          visible={visible}
          onCancel={this.handleCancel}
          footer={null}
          centered
        >
          <Body
            isSalla={isSalla}
            isArabic={isArabic}
            onboarding={onboarding}
            handleOnboardingSteps={this.handleOnboardingSteps}
            currentSuit={this.currentSuit}
            router={router}
            subdomain={this.currentSuit?.subdomain}
            handleSteps={this.handleSteps}
            handleBack={this.handleBack}
            handleSMSType={this.handleSMSType}
            handleCancel={this.handleCancel}
            handleProgress={this.handleProgress}
            handleChosenSMSType={this.handleChosenSMSType}
            chosenSMSType={this.state.chosenSMSType}
            smsType={this.state.smsType}
            handleStepNum={this.handleStepNum}
            handleRestart={this.handleRestart}
          />
        </Modal>
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getMobileAppPlans,
    updateSuit,
    getCurrentSuit,

    showNotification: Notifications.show,
    getUserSuits,
  }
)(OnBoardingModal);

OnBoardingModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

OnBoardingModal.propTypes = {
  user: PropTypes.object.isRequired,
  getMobileAppPlans: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  updateSuit: PropTypes.func.isRequired,
  getCurrentSuit: PropTypes.func.isRequired,
  getUserSuits: PropTypes.func.isRequired,
};
