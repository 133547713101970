import { userData } from "modules/user";
import apiEndpoints from "../consts/api";

const uploadToAmazon = async (
  componentName,
  properties,
  imageSource,
  callback
) => {
  let formData = new FormData();

  const handleSuccessfulCase = async (type) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(imageSource);
    await new Promise((resolve) => {
      fileReader.onloadend = resolve;
    });

    if (type === "video" && imageSource.size <= 500000) {
      formData.append("video", imageSource);
    } else {
      formData.append("photo", imageSource);
    }

    const response = await fetch(
      apiEndpoints.UPLOAD.upload(properties?.user?.activeSuitId),
      {
        method: "POST",
        body: formData,
        headers: {
          authToken: userData().auth_token,
        },
      }
    );

    if (response.ok) {
      let link = await response.text();
      link = `https://${link}`;
      callback({ data: { link } });
    }
  };

  if (imageSource) {
    if (componentName === "notification_video.src") {
      if (imageSource.type !== "video/mp4") {
        properties.showNotification(
          {
            title: "Video extension error",
            message: "Please upload video with MP4 extension",
            position: "bc",
            autoDismiss: 5,
          },
          "error"
        );
        callback(false);
      }

      if (imageSource.size > 500000) {
        properties.showNotification(
          {
            title: "Video size error",
            message: "Maximum file size is 500 kb",
            position: "bc",
            autoDismiss: 5,
          },
          "error"
        );
        callback(false);
      } else {
        await handleSuccessfulCase("video");
      }
    }

    var image = document.createElement("img");
    image.onload = async function () {
      if (
        componentName === "socialmedia_settings.image" ||
        componentName === "app_icon_url"
      ) {
        if (this.width === 512 && this.height === 512) {
          await handleSuccessfulCase();
        } else {
          properties.showNotification(
            {
              title: "img size error",
              message: "Please add the img with size 512px * 512px",
              position: "bc",
              autoDismiss: 5,
            },
            "error"
          );
          callback({ data: "false" });
        }
      } else if (componentName === "imgfile") {
        await handleSuccessfulCase();
      } else if (componentName === "notification_image.src") {
        if (this.width === 64 && this.height === 64) {
          await handleSuccessfulCase();
        } else {
          properties.showNotification(
            {
              title: "img size error",
              message: "Please add the img with size 64px * 64px",
              position: "bc",
              autoDismiss: 5,
            },
            "error"
          );
          callback({ data: "false" });
        }
      } else if (componentName) {
        await handleSuccessfulCase();
      }
    };

    var imageReader = new FileReader();
    imageReader.onloadend = function (ended) {
      image.src = ended.target.result;
    };
    imageReader.readAsDataURL(imageSource);
  }
};

export default uploadToAmazon;
