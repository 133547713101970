import { Icon, Modal, Spin, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import FacebookLogin from "react-facebook-login";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import endpoints from "../../../../consts/api";
import superAgentInstance from "../../../../modules/api";
import classes from "./ExternalIntegrations.scss";

const fields = [
  {
    id: "1",
    label: "SettingsPage_integrations_Google_title",
    description: "SettingsPage_integrations_Google_desc",
    propertyName: "google_analytics",
    placeholder: "N/A",
  },
  {
    id: "2",
    label: "SettingsPage_integrations_HotjarID_title",
    description: "SettingsPage_integrations_HotjarID_desc",
    propertyName: "hotjar_id",
    placeholder: "N/A",
  },
  {
    id: "3",
    label: "SettingsPage_integrations_StripePublishableKey_title",
    description: "SettingsPage_integrations_StripePublishableKey_desc",
    propertyName: "stripe_key",
    placeholder: "N/A",
  },
  {
    id: "4",
    label: "SettingsPage_integrations_facebook_title",
    description: "SettingsPage_integrations_facebook_desc",
    propertyName: "FBpixel_tracking_code",
    placeholder: "N/A",
  },
  {
    id: "5",
    label: "SettingsPage_integrations_Twitter_title",
    description: "SettingsPage_integrations_Twitter_desc",
    propertyName: "twitter_pixel_id",
    placeholder: "N/A",
  },
  {
    id: "6",
    label: "SettingsPage_integrations_GoogleAds_title",
    description: "SettingsPage_integrations_GoogleAds_desc",
    propertyName: "google_ads_id",
    placeholder: "N/A",
  },
  {
    id: "7",
    label: "SettingsPage_integrations_Snapchat_title",
    description: "SettingsPage_integrations_Snapchat_desc",
    propertyName: "snapchat_id",
    placeholder: "N/A",
  },
  {
    id: "8",
    label: "SettingsPage_integrations_LinkedIn_title",
    description: "SettingsPage_integrations_LinkedIn_desc",
    propertyName: "linkedIn_id",
    placeholder: "N/A",
  },
  {
    id: "9",
    label: "SettingsPage_integrations_TikTok_title",
    description: "SettingsPage_integrations_TikTok_desc",
    propertyName: "tiktok_pixel_id",
    placeholder: "N/A",
  },
];

const RenderRow = ({
  label,
  description,
  propertyName,
  placeholder,
  currentSuit,
}) => {
  return (
    <div className={classes.row}>
      <div className={classes.labelContainer}>
        <p className={classes.label}>{I18n.t(label)}</p>
        <Tooltip title={I18n.t(description)} placement="right">
          <Icon
            type="info-circle"
            theme="twoTone"
            twoToneColor="#630bde"
            style={{ fontSize: "16px" }}
          />
        </Tooltip>
      </div>
      <p className={classes.value}>
        {currentSuit[propertyName] || placeholder}
      </p>
    </div>
  );
};

RenderRow.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  propertyName: PropTypes.string,
  placeholder: PropTypes.string,
  currentSuit: PropTypes.object,
};

const RenderInputRow = ({ label, propertyName, formData, changeFormData }) => {
  return (
    <div className={classes.inputRowContainer}>
      <label htmlFor={propertyName}>{I18n.t(label)}</label>
      <input
        id={propertyName}
        className={classes.input}
        value={formData[propertyName]}
        onChange={(e) =>
          changeFormData({ ...formData, [propertyName]: e.target.value })
        }
        type="text"
      />
    </div>
  );
};

class ExternalIntegrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIntegrationsModal: false,

      formData: {},

      editFieldsLoading: false,
    };
  }

  onEditButtonClick = () => {
    // prepare form data
    const { currentSuit } = this.props;

    const formData = {};
    fields.forEach((field) => {
      formData[field.propertyName] = currentSuit[field.propertyName] || "";
    });

    this.setState({ showIntegrationsModal: true, formData });
  };

  handleEditIntegrationsModalOk = () => {};

  changeFormData = (formData) => {
    this.setState({ formData });
  };

  handleSaveButtonClick = async () => {
    const {
      user: { activeSuitId },
      updateSuit,
      getCurrentSuit,
      showNotification,
      currentSuit,
    } = this.props;

    const { formData } = this.state;
    // get form data that has changed
    const changedFields = {};
    fields.forEach((field) => {
      if (formData[field.propertyName] !== currentSuit[field.propertyName]) {
        changedFields[field.propertyName] = formData[field.propertyName];
      }
    });

    // update suit
    try {
      this.setState({ editFieldsLoading: true });
      await updateSuit(activeSuitId, {
        ...changedFields,
        suit_name: currentSuit.name,
      });

      // get updated suit
      await getCurrentSuit(activeSuitId);

      showNotification(
        {
          title: "",
          message: I18n.t("SettingsPage_integrations_editMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );

      this.setState({ showIntegrationsModal: false });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message: "Error updating suit",
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    } finally {
      this.setState({ showIntegrationsModal: false, editFieldsLoading: false });
    }
  };

  generateLongAccessToken = async (shortToken) => {
    try {
      const response = await superAgentInstance(
        "GET",
        endpoints.FACEBOOK.generateLongAccessToken(shortToken)
      );
      console.log("generateLongAccessToken", response);
      return response.body.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  responseFacebook = async (response) => {
    const {
      updateSuit,
      user: { activeSuitId, suit, suits },
      showNotification,
    } = this.props;

    const currentSuit =
      suit || suits.filter((suit) => suit.id === activeSuitId)[0];

    const shortToken = response.accessToken;

    try {
      const longTokenResponse = await this.generateLongAccessToken(shortToken);

      const longToken = longTokenResponse.access_token;

      await updateSuit(activeSuitId, {
        suit_name: currentSuit.name,
        FBSessionToken: longToken,
      });

      showNotification(
        {
          title: "",
          message: I18n.t(
            "ProjectSettingPage_externalIntegrations_FBLinkedMsg"
          ),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
      this.setState({ displayFacebookLogin: false });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message: I18n.t("ProjectSettingPage_externalIntegrations_ErrMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    }
  };

  onFacebookLoginFail = (error) => {
    console.error(error);
  };

  render() {
    const { currentSuit } = this.props;

    const { showIntegrationsModal, formData, editFieldsLoading } = this.state;

    return (
      <div className={classes.externalIntegrations}>
        <h2 className={classes.title}>
          {I18n.t("SettingsPage_integrations_title")}
        </h2>

        <div className={classes.fieldsContainer}>
          {fields.map((field) => (
            <RenderRow key={field.id} {...field} currentSuit={currentSuit} />
          ))}
        </div>

        <div className={classes.buttonsContainer}>
          <button
            className={classes.editButton}
            onClick={this.onEditButtonClick}
          >
            {I18n.t("edit")}
          </button>
        </div>

        <FacebookLogin
          appId="2242381319117291"
          callback={this.responseFacebook}
          onFailure={this.onFacebookLoginFail}
          cssClass={classes.facebookConnectBtn}
          scope="ads_management,ads_read,business_management,public_profile"
        />

        {/* edit fields modal */}
        <Modal
          title="Edit Integrations"
          visible={showIntegrationsModal}
          // onOk={this.handleEditIntegrationsModalOk}
          onCancel={() => this.setState({ showIntegrationsModal: false })}
          footer={null}
          className={classes.modal}
        >
          <Spin spinning={editFieldsLoading}>
            <div className={classes.editModalContainer}>
              {fields.map((field) => (
                <RenderInputRow
                  key={field.id}
                  {...field}
                  formData={formData}
                  changeFormData={this.changeFormData}
                />
              ))}
            </div>

            <div className={classes.buttonsContainer}>
              <button
                className={classes.saveButton}
                onClick={this.handleSaveButtonClick}
              >
                {I18n.t("save")}
              </button>
              <button
                className={classes.cancelButton}
                onClick={() => this.setState({ showIntegrationsModal: false })}
              >
                {I18n.t("cancel")}
              </button>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    showNotification: Notifications.show,
    updateSuit,
    getCurrentSuit,
  }
)(ExternalIntegrations);

ExternalIntegrations.propTypes = {
  currentSuit: PropTypes.object,
  user: PropTypes.object,
  showNotification: PropTypes.func,
  updateSuit: PropTypes.func,
  getCurrentSuit: PropTypes.func,
};
