import { Icon, Input, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { locale } from "../../../modules/i18n";
import classes from "./TextArea.scss";
const { TextArea: AntdTextArea } = Input;

export default function TextArea({
  label,
  hint,
  tooltip,
  input,
  meta,
  placeholder,
  disabled,
  className,
  maxRecommended,
  minRecommended,
  maxLength,
  minLength,
  rows,
  style,
  EmojiComponent,
  id,
}) {
  const inputLength = input?.value?.length;
  const currentLocale = locale();
  const isArabic = currentLocale === "ar";

  return (
    <div style={style} className={`${className} ${classes.textArea}`}>
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon type="info-circle" theme="twoTone" twoToneColor="#630bde" />
            </Tooltip>
          )}
        </label>
      )}

      <div className={classes.inputContainer}>
        <AntdTextArea
          id={id}
          className={classes.textarea}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          rows={rows || 4}
          {...input}
        />
        {EmojiComponent && (
          <div
            className={`${classes.emojiContainer} ${
              isArabic ? classes.rtl : ""
            }`}
          >
            <EmojiComponent />
          </div>
        )}
        {maxRecommended && (
          <div
            className={`${classes.maxRecommended} ${
              inputLength > maxRecommended ||
              (inputLength < minRecommended && inputLength !== 0)
                ? classes.maxRecommendedReached
                : ""
            } ${isArabic && classes.rtl}`}
          >
            {inputLength}/{maxRecommended}
          </div>
        )}
      </div>

      {hint && (
        <div className={classes.hint}>
          <span>{hint}</span>
        </div>
      )}

      <div>
        {meta.touched && meta.error && (
          <span className={classes.error}>{meta.error}</span>
        )}
      </div>
    </div>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  tooltip: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxRecommended: PropTypes.number,
  minRecommended: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  rows: PropTypes.number,
  style: PropTypes.object,
  EmojiComponent: PropTypes.func,
  id: PropTypes.string,
};
