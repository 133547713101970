import { Tooltip } from "antd";
import { locale } from "modules/i18n";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./FileUploadInput.scss";

const adaptFileEventToValue = (delegate) => (e) => {
  delegate(e.target.files[0]);
};

const FileUploadInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  iconClass,
  hint,
  label,
  optional,
  infoText,
  fileSizeError,
  ...props
}) => {
  const currentLocale = locale();

  return (
    <div className={classes.fileUploadInput}>
      {label && (
        <label className={classes.label}>
          {label} {optional && I18n.t("optional")}
        </label>
      )}
      <div className={classes.inputContainer}>
        <input
          readOnly
          type="text"
          className={classes.readOnlyInput}
          value={omitValue.name ? omitValue.name : props.placeholder}
        />
        <button
          type="button"
          className={`${classes.uploadBtn} ${
            currentLocale === "ar" && classes.rtl
          }`}
        >
          <i className={iconClass} />
        </button>
        <input
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          type="file"
          className={classes.fileInput}
          {...inputProps}
          {...props}
        />
      </div>
      {infoText && (
        <Tooltip title={infoText}>
          <span>
            <i className="fa fa-info help-icon" />
          </span>
        </Tooltip>
      )}
      {hint && <p className={classes.hintText}>{hint}</p>}
      {fileSizeError && (
        <p className={classes.imgSizeError}>
          {I18n.t("createMicroPagesPage_MLPHeaderStep_fileSize")}.
        </p>
      )}
    </div>
  );
};
export default FileUploadInput;

FileUploadInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  iconClass: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  infoText: PropTypes.string,
  fileSizeError: PropTypes.bool,
};
