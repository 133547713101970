import { Icon, Input, Tooltip } from "antd";
import { DeepLinkBuilder } from "components";
import { locale } from "modules/i18n";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./TextBox.scss";

export default function TextBox({
  id,
  input,
  hint,
  label,
  className,
  placeholder,
  meta,
  type,
  hasTooltip,
  tooltipBehind,
  rightInputHint,
  disabled,
  animationClassName,
  maxLength,
  maxRecommended,
  minRecommended,
  rightBottomHint,
  deepLinkBuilder,
  deepLinkType,
  noDeeplink,
  nomail,
  FiledNotexist,
  onlymdp,
  forgotPasswordClick,
  style,
  readOnly,
  shadow,
}) {
  const currentLocale = locale();
  const isArabic = currentLocale === "ar";
  const inputLength = input?.value?.length;
  const isErrored = meta.touched && meta.error;

  return (
    <div style={style} className={`${className} ${animationClassName}`}>
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label}
          {hasTooltip && (
            <Tooltip title={tooltipBehind}>
              <Icon type="info-circle" theme="twoTone" twoToneColor="#630bde" />
            </Tooltip>
          )}
        </label>
      )}
      <div className={classes.inputContainer}>
        {forgotPasswordClick && (
          <button
            type="button"
            onClick={forgotPasswordClick}
            className={`${classes.forgotPasswordClick} ${
              isArabic && classes.rtl
            }`}
          >
            {I18n.t("loginPage_ForgotPassword")}
          </button>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "12px",
            justifyContent: "flex-start",
          }}
        >
          <Input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            className={`${classes.input} ${isArabic && classes.rtl} ${
              isErrored && classes.error
            }`}
            type={type}
            maxLength={maxLength}
            readOnly={readOnly}
            style={{
              boxShadow: shadow,
              flex: 1,
            }}
            {...input}
          />
          {deepLinkBuilder && (
            <DeepLinkBuilder
              type={deepLinkType}
              notIn={FiledNotexist}
              nomail={nomail}
              callBack={input.onChange}
              noDeeplink={noDeeplink}
              onlymdp={onlymdp}
            />
          )}
        </div>
        {maxRecommended && (
          <div
            className={`${classes.maxRecommended} ${
              inputLength > maxRecommended ||
              (inputLength < minRecommended && inputLength !== 0)
                ? classes.maxRecommendedReached
                : ""
            } ${isArabic && classes.rtl}`}
          >
            {inputLength}/{maxRecommended}
          </div>
        )}
        {rightInputHint && (
          <div
            className={`${classes["right-input-hint"]} ${
              isArabic && classes.rtl
            }`}
          >
            {I18n.t(rightInputHint)}
          </div>
        )}
      </div>
      {hint && (
        <div className={classes.hint}>
          <span>{hint}</span>
        </div>
      )}

      <div style={{ display: "flex" }}>
        <div>
          {meta?.touched && meta?.error && (
            <span className={classes.error}>{meta.error}</span>
          )}
        </div>

        {rightBottomHint && (
          <div style={{ marginLeft: "auto" }}>
            <span style={{ marginBottom: "0" }}>{rightBottomHint}</span>
          </div>
        )}
      </div>
    </div>
  );
}
