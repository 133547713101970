import { ConfigProvider } from "antd";
import ar from "antd/es/locale/ar_EG";
import en from "antd/es/locale/en_US";
import tr from "antd/es/locale/tr_TR";
import { locale } from "modules/i18n";
import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import classes from "./AppContainer.scss";

const languageMapper = {
  ar: ar,
  en: en,
  tr: tr,
};

class AppContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
    routerKey: PropTypes.number,
    store: PropTypes.object.isRequired,
  };

  render() {
    const { history, routes, routerKey, store } = this.props;

    const currentLocale = locale();

    return (
      <Provider store={store}>
        <ConfigProvider locale={languageMapper[currentLocale]}>
          <div className={classes["app-container"]}>
            <Router history={history} key={routerKey}>
              {routes}
            </Router>
          </div>
        </ConfigProvider>
      </Provider>
    );
  }
}

export default AppContainer;
