export const extractWhatsAppVariables = (message) => {
  const regex = /{{\s*([\w\d]+)\s*}}/g;
  const variables = [];
  let match;

  while ((match = regex.exec(message)) !== null) {
    const variable = match[1];
    variables.push(variable);
  }

  return variables;
};

const BODY = "BODY";
const HEADER = "HEADER";

export const extractWhatsAppComponents = (components) => {
  let bodyVariables;
  let headerVariables;
  let bodyComponent;
  let headerComponent;
  let notSupportedComponents = [];

  Object.values(components).forEach((component) => {
    if (component.type === BODY) {
      bodyVariables = extractWhatsAppVariables(component.text);
      bodyComponent = component;
    }

    if (component.type === HEADER) {
      if (component["text"] && component["format"] === "TEXT") {
        headerVariables = extractWhatsAppVariables(component.text);
        headerComponent = component;
      } else if (component["format"] !== "TEXT") {
        notSupportedComponents.push(component);
      }
    }
  });

  return {
    body: bodyVariables,
    header: headerVariables,
    bodyComponent,
    headerComponent,
    notSupportedComponents,
  };
};
