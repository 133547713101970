import { Alert, Col, Row, SlimProgressBar } from "components";
import { userData } from "modules/user";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  PostalCodeElement,
} from "react-stripe-elements";
import { submitStripeToken } from "reducers/user";
import classes from "./StripeForm.scss";

class StripeForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      error: false,
      valid: true,
      errormessage: null,
    };
  }
  handleSubmit = () => {
    this.setState({ show: true });
    this.props.stripe.createToken().then(({ token }) => {
      if (token && !token.error) {
        this.setState({ valid: false, show: true });
        this.props
          .submitStripeToken(token.id, userData().user_id)
          .then(() => {
            this.props.submitForm();
          })
          .catch(() => {
            this.setState({ valid: true });
            this.props.resetSubmit && this.props.resetSubmit();
          });
      } else if (token && token.error) {
        this.setState({
          show: false,
          error: true,
          errormessage: token.error.message,
        });
        setTimeout(() => {
          this.setState({ error: false });
        }, 2500);
      } else {
        this.props.resetSubmit && this.props.resetSubmit();
        this.setState({ show: false, error: true });
        setTimeout(() => {
          this.setState({ error: false });
        }, 2500);
      }
    });
    setTimeout(() => {
      this.setState({ show: false });
    }, 3500);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.submitted && this.state.valid) {
      this.handleSubmit();
    }
  }

  render() {
    const { edit } = this.props;
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        {this.state.error && (
          <Alert className={classes.alert} bsStyle>
            <h4>
              {this.state.error
                ? this.state.errormessage === null
                  ? I18n.t("stripForm_alert_noerror")
                  : this.state.errormessage
                : I18n.t("stripForm_alert_error")}
            </h4>
            {!this.state.error && <SlimProgressBar now={70} size="medium" />}
          </Alert>
        )}
        {edit ? (
          <div className={classes.edit}>
            <Row>
              <Col sm={6}>
                <div className={classes.inputRow}>
                  <div className={classes.elementLabel}>
                    {I18n.t("stripForm_alert_cardNum")}
                  </div>
                  <CardNumberElement />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <div className={classes.inputRow}>
                  <div className={classes.elementLabel}>
                    {I18n.t("stripForm_alert_Expirationdate")}
                  </div>
                  <CardExpiryElement />
                </div>
              </Col>
              <Col sm={5}>
                <div className={classes.inputRow}>
                  <div className={classes.elementLabel}>
                    {I18n.t("stripForm_alert_Postalcode")}
                  </div>
                  <PostalCodeElement />
                </div>
              </Col>
              <Col sm={2}>
                <div className={classes.inputRow}>
                  <div className={classes.elementLabel}>
                    {I18n.t("stripForm_alert_CVC")}
                  </div>
                  <CardCVCElement />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <div className={classes.inputRow}>
              <div className={classes.elementLabel}>
                {I18n.t("stripForm_alert_cardNum")}
              </div>
              <CardNumberElement />
            </div>
            <div className={classes.inputRow}>
              <div className={classes.elementLabel}>
                {I18n.t("stripForm_alert_Expirationdate")}
              </div>
              <CardExpiryElement />
            </div>
            <div className={classes.inputRow}>
              <div className={classes.elementLabel}>
                {I18n.t("stripForm_alert_CVC")}
              </div>
              <CardCVCElement />
            </div>
            <div className={classes.inputRow}>
              <div className={classes.elementLabel}>
                {I18n.t("stripForm_alert_Postalcode")}
              </div>
              <PostalCodeElement />
            </div>
          </div>
        )}
      </form>
    );
  }
}

export default injectStripe(
  connect(() => ({}), { submitStripeToken })(StripeForm)
);
