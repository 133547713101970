import { SpinLoader } from "components";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import classes from "./OnBoardingModal.scss";
import {
  importAllProducts,
  importAllSallaProducts,
  importAllZidProducts,
} from "./ShortLinkLogic";

class ShortLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPending: false,
      createLink: false,
    };
  }

  handleImport = async () => {
    const {
      showNotification,
      user: { activeSuitId },
      currentSuit: { source, web_url },
    } = this.props;

    this.setState({ isPending: true });

    if (source == "Shopify") {
      try {
        const shopDomain = web_url?.startsWith("https://")
          ? web_url.split("https://")[1]
          : web_url;

        await importAllProducts(shopDomain, activeSuitId);

        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_successMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );

        this.props.handleOnboardingSteps("smartLinks", "", "Shopify");

        this.props.handleSteps();
      } catch (error) {
        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_errorMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "error"
        );
      } finally {
        this.setState({ isPending: false });
      }
    } else if (source == "Zid") {
      try {
        await importAllZidProducts(activeSuitId);

        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_successMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        this.props.handleOnboardingSteps("smartLinks", "", "Zid");

        this.props.handleSteps();
      } catch (error) {
        console.error(error);
        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_errorMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "error"
        );
      } finally {
        this.setState({ isPending: false });
      }
    } else if (source == "Salla") {
      try {
        await importAllSallaProducts(activeSuitId);

        this.props.handleOnboardingSteps("smartLinks", "", "Salla");

        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_successMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        this.props.handleSteps();
      } catch (error) {
        showNotification(
          {
            title: "",
            message: I18n.t("onBoarding_links_errorMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "error"
        );
      } finally {
        this.setState({ isPending: false });
      }
    }
  };
  render() {
    const { handleSteps, handleBack, isArabic } = this.props;
    const { isPending } = this.state;

    return (
      <div className={classes.ShortLinks}>
        {isPending && <SpinLoader />}

        <p>{I18n.t("onBoarding_links_desc")}</p>
        <button
          onClick={() => {
            this.handleImport();
          }}
        >
          {I18n.t("onBoarding_links_Btn")}
        </button>
        <div className="buttonsContainer">
          <button
            onClick={() => {
              handleBack();
            }}
            className="confirm cancel"
          >
            {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
            <span>{I18n.t("backBtn")}</span>
          </button>
          <button
            onClick={() => {
              handleSteps();
            }}
            className="confirm"
          >
            <span>{I18n.t("continueBtn")}</span>
            {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    showNotification: Notifications.show,
    updateSuit,
    getCurrentSuit,
  }
)(ShortLinks);
